/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import styled from '@emotion/styled'
import {useState} from 'react'
import {graphql} from 'gatsby'
import RouterLink from '../components/RouterLink'
import Markdown from 'react-markdown'
import {Container, Page, Intro, button} from '../styles'
import Layout from '../components/Layout'
import Donorbox from '../components/Donorbox'
import DividerPanda from '../components/DividerPanda'

const ExpandOrCollapse = styled('div')`
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
    ${({expanded}) => {
        return (
            expanded &&
            css`
                max-height: 10000px;
                transition: max-height 1s ease-in-out;
            `
        )
    }}
`

const DonatePage = ({
    data: {
        markdownRemark: {
            frontmatter: {title, intro, buttonLabel},
            rawMarkdownBody,
        },
    },
}) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <Layout>
            <Page>
                <Container>
                    <Intro>
                        <h1>{title}</h1>
                        <p>{intro}</p>
                        <ExpandOrCollapse expanded={expanded}>
                            <Donorbox />
                        </ExpandOrCollapse>
                        <button
                            css={button}
                            onClick={() => {
                                setExpanded(!expanded)
                            }}
                        >
                            {expanded ? 'Nevermind' : buttonLabel}
                        </button>
                    </Intro>
                </Container>
                <DividerPanda />
                <Container>
                    <Markdown
                        source={rawMarkdownBody}
                        renderers={{link: RouterLink}}
                    />
                </Container>
                <script
                    src="https://donorbox.org/widget.js"
                    paypalexpress="true"
                />
            </Page>
        </Layout>
    )
}

export default DonatePage

export const query = graphql`
    query {
        markdownRemark(frontmatter: {id: {eq: "donate"}}) {
            frontmatter {
                id
                title
                intro
                buttonLabel
            }
            rawMarkdownBody
        }
    }
`
