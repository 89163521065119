/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {useState} from 'react'
import skeleton from '../images/skeleton.jpg'

function Donorbox() {
    const [isLoading, setIsLoading] = useState(true)
    return (
        <div
            css={css`
                position: relative;
                display: 'flex';
                align-items: center;
                flex-direction: column;
                margin-top: 2rem;
            `}
        >
            <iframe
                src="https://donorbox.org/embed/mwb-sustainers"
                onLoad={() => setIsLoading(false)}
                title="Donorbox"
                height="685px"
                width="100%"
                css={css`
                    max-width: 500px;
                    min-width: 310px;
                    max-height: none;
                    z-index: 10;
                    opacity: ${isLoading ? 0 : 1};
                    transition: opacity 0.2s ease-in-out;
                `}
                seamless="seamless"
                name="donorbox"
                frameBorder="0"
                scrolling="no"
                allowpaymentrequest="true"
            />
            <img
                src={skeleton}
                alt=""
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    max-width: 426px;
                    z-index: -1;
                    opacity: ${isLoading ? 1 : 0};
                    transition: opacity 0.2s ease-in-out;
                `}
            />
        </div>
    )
}

export default Donorbox
